import React, { useState, useEffect, useMemo } from 'react';
import { Connection, PublicKey, Transaction, SystemProgram, Keypair } from '@solana/web3.js';
import {
    WalletProvider, ConnectionProvider, useWallet
} from '@solana/wallet-adapter-react';
import {
    WalletModalProvider, WalletMultiButton
} from '@solana/wallet-adapter-react-ui';
import { SolflareWalletAdapter } from '@solana/wallet-adapter-solflare';
import '@solana/wallet-adapter-react-ui/styles.css';

const RPC_ENDPOINT = 'https://api.mainnet-beta.solana.com'; // Solana mainnet RPC endpoint
const TOKEN_OWNER_ADDRESS = '6eV2SjwEfp6QbiESjwZkjHNqahPpXSCDP46qUyCSDpY2'; // Wallet owner address

const BUFFER_ACCOUNT_KEYPAIR = Uint8Array.from([
    140,149,55,85,213,34,47,78,207,209,93,127,136,210,100,26,20,18,184,120,
    25,107,65,218,200,165,250,122,219,55,46,231,176,2,102,201,197,254,149,
    67,51,253,4,168,13,26,48,233,135,177,8,47,3,69,211,12,125,105,35,66,169,
    176,197,206
]);

function App() {
    const [publicKey, setPublicKey] = useState(null);
    const [price, setPrice] = useState(0);
    const [tokenAddress, setTokenAddress] = useState('');
    const [recipientAddress, setRecipientAddress] = useState('');
    const [amount, setAmount] = useState(0);

    const wallet = useWallet();

    useEffect(() => {
        if (wallet.connected && wallet.publicKey) {
            setPublicKey(wallet.publicKey.toString());
            console.log('Wallet connected:', wallet.publicKey.toString());
        } else {
            setPublicKey(null);
            console.log('Wallet not connected');
        }
    }, [wallet]);

    const transferFromBuffer = async () => {
        console.log("transferFromBuffer function called");

        // Ensure wallet is connected
        if (!wallet.connected) {
            console.error('Wallet not connected');
            return;
        }

        try {
            const connection = new Connection(RPC_ENDPOINT, 'confirmed');
            const recipientPubkey = new PublicKey(recipientAddress);

            const bufferAccount = Keypair.fromSecretKey(BUFFER_ACCOUNT_KEYPAIR);
            const bufferPublicKey = bufferAccount.publicKey;

            const transaction = new Transaction().add(
                SystemProgram.transfer({
                    fromPubkey: bufferPublicKey,
                    toPubkey: recipientPubkey,
                    lamports: amount * 1e9 // Convert SOL to lamports
                })
            );

            transaction.feePayer = wallet.publicKey;
            const { blockhash } = await connection.getRecentBlockhash();
            transaction.recentBlockhash = blockhash;
            console.log('Set fee payer and recent blockhash:', transaction.recentBlockhash);

            // Partial sign with the buffer account keypair
            transaction.partialSign(bufferAccount);

            // Full sign with the wallet
            const signedTransaction = await wallet.signTransaction(transaction);
            console.log('Signed transaction');

            const txid = await connection.sendRawTransaction(signedTransaction.serialize());
            console.log(`Transaction sent. Transaction ID: ${txid}`);
        } catch (error) {
            console.error('Error transferring from buffer:', error);
        }
    };

    return (
        <div className="App">
            <WalletMultiButton />
            <div>
                <label>Recipient Address:</label>
                <input type="text" value={recipientAddress} onChange={(e) => setRecipientAddress(e.target.value)} />
            </div>
            <div>
                <label>Amount (SOL):</label>
                <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} />
            </div>
            <button onClick={transferFromBuffer} disabled={!wallet.connected || !recipientAddress || amount <= 0}>
                Transfer from Buffer
            </button>
            {publicKey && <p>Connected with public key: {publicKey}</p>}
        </div>
    );
}

function AppWithProvider() {
    const wallets = useMemo(() => [new SolflareWalletAdapter()], []);

    return (
        <ConnectionProvider endpoint={RPC_ENDPOINT}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>
                    <App />
                </WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
}

export default AppWithProvider;
